import { AccountInfo, EventType } from '@azure/msal-browser';
import { useCallback } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import { getClaim, msal, ofType } from '../common';
import { Role } from '../common/models';

export const permissions = {
    dashboard: {
        realtime: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.DashboardUser,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.AccountManagementCoordinator,
        ],
        reporting: [
            Role.TechnicalUser,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.DashboardUser,
            Role.AccountManagementCoordinator,
        ],
    },
    systemStatus: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.DashboardUser,
            Role.AccountManagementCoordinator,
        ],
    },
    changeRequests: {
        view: [Role.ChangeManager, Role.UserAdmin, Role.TechnicalUser],
        approve: [Role.ChangeManager],
        reject: [Role.ChangeManager],
    },
    customers: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
        ],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.MerchantAnalyst,
        ],
        create: [Role.PosTester],
        edit: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst, Role.ComplianceOfficer],
        editKycFlags: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst, Role.ComplianceOfficer],
        block: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        unblock: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        close: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        reopen: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        download: [Role.OperationsManager],
    },
    customerCards: {
        transfer: [Role.OperationsManager, Role.OperationsAnalyst],
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
        ],
    },
    customerDataModifications: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
        viewAuditTrail: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
        review: [Role.OperationsManager, Role.OperationsAnalyst],
        reject: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    customerDocuments: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
        ],
        upload: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        edit: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
    },
    customerRemarks: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
        remove: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    customerKycRequests: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
        review: [Role.OperationsManager, Role.OperationsAnalyst],
        reject: [Role.OperationsManager, Role.OperationsAnalyst],
        submitData: [Role.OperationsManager, Role.OperationsAnalyst],
        cancel: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    customerPeriodicReviews: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
    },
    customerReKycVerifications: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
    },
    customerVerifications: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        edit: [Role.OperationsManager, Role.OperationsAnalyst],
        review: [Role.OperationsManager, Role.OperationsAnalyst],
        reject: [Role.OperationsManager, Role.OperationsAnalyst],
        accept: [Role.OperationsManager, Role.OperationsAnalyst],
        askDocuments: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    customerDataDownloads: {
        // TODO: check roles
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
        download: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.FinanceOfficer],
    },
    trainingAssignments: {
        view: [
            Role.TechnicalUser,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.OperationsManager,
            Role.ComplianceOfficer,
            Role.DistributorUser,
            Role.AppStoreReviewer,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
            Role.ComplianceOfficer,
        ],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.OperationsManager,
            Role.ComplianceOfficer,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
            Role.ComplianceOfficer,
        ],
        create: [Role.AccountManagementCoordinator, Role.OperationsAnalyst, Role.ComplianceOfficer],
        complete: [Role.AccountManagementCoordinator, Role.OperationsAnalyst, Role.ComplianceOfficer],
        cancel: [Role.AccountManagementCoordinator, Role.OperationsAnalyst, Role.ComplianceOfficer],
    },
    transactions: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.AccountManagementCoordinator,
            Role.MerchantUser,
            Role.DistributorManager,
            Role.DistributorUser,
        ],
        filterRealtime: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        filterReport: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.AccountManagementCoordinator,
            Role.MerchantUser,
            Role.DistributorManager,
            Role.DistributorUser,
        ],
        download: [Role.OperationsManager, Role.OperationsAnalyst, Role.ComplianceOfficer],
        reverse: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        refund: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst, Role.MerchantUser],
        cancel: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
        complete: [Role.OperationsManager, Role.OperationsAnalyst, Role.MerchantAnalyst],
    },
    transactionIntents: {
        view: [
            Role.TechnicalUser,
            Role.PosTester,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        filterRealtime: [
            Role.TechnicalUser,
            Role.PosTester,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        filterReport: [
            Role.TechnicalUser,
            Role.PosTester,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        create: [Role.PosTester],
    },
    accounts: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        viewTransactionEntries: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
        edit: [Role.TechnicalUser],
        calculateBalance: [Role.TechnicalUser],
    },
    distributors: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        create: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        edit: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        activate: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        block: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        unblock: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        initiateClosure: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        close: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
    },
    shops: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.FinanceOfficer,
            Role.MerchantAnalyst,
            Role.AccountManagementCoordinator,
            Role.ComplianceOfficer,
        ],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
            Role.ComplianceOfficer,
        ],
        create: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        edit: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        close: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        reopen: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
    },
    devices: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.DistributorManager,
            Role.MerchantAnalyst,
            Role.AccountManagementCoordinator,
        ],
        edit: [Role.OperationsManager, Role.OperationsAnalyst, Role.DistributorManager, Role.AccountManagementCoordinator],
        create: [Role.OperationsAnalyst, Role.DistributorManager],
        changeShop: [Role.OperationsManager, Role.OperationsAnalyst, Role.DistributorManager, Role.AccountManagementCoordinator],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.AccountManagementCoordinator,
        ],
        viewUserDeviceAuthorizations: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
        ],
        activate: [Role.OperationsManager, Role.OperationsAnalyst, Role.DistributorManager, Role.AccountManagementCoordinator],
        deactivate: [Role.OperationsManager, Role.OperationsAnalyst, Role.DistributorManager, Role.AccountManagementCoordinator],
        retire: [Role.OperationsManager, Role.OperationsAnalyst, Role.DistributorManager, Role.AccountManagementCoordinator],
        denyUser: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
        ],
        allowUser: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
        ],
    },
    merchants: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
            Role.FinanceOfficer,
            Role.AccountManagementCoordinator,
        ],
        create: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        edit: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        activate: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        block: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
        unblock: [Role.OperationsManager, Role.OperationsAnalyst, Role.AccountManagementCoordinator],
    },
    limits: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        create: [Role.OperationsManager],
        edit: [Role.OperationsManager],
    },
    limitAccountSets: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        create: [Role.OperationsAnalyst, Role.OperationsManager],
    },
    limitAccounts: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        edit: [Role.OperationsAnalyst, Role.OperationsManager],
        create: [],
        remove: [],
    },
    settlementExportFiles: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.FinanceOfficer],
        create: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.FinanceOfficer],
        send: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.FinanceOfficer],
        download: [Role.TechnicalUser, Role.FinanceOfficer, Role.OperationsManager],
    },
    settlementImportFiles: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.FinanceOfficer],
        create: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.FinanceOfficer],
        reprocess: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.FinanceOfficer],
        reprocessTransaction: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.FinanceOfficer],
        download: [Role.TechnicalUser, Role.FinanceOfficer, Role.OperationsManager],
    },
    identityVerifications: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.TechnicalUser, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
        retry: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    identityVerificationFiles: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.TechnicalUser, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
        reprocess: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    notifications: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalystReadonly, Role.OperationsAnalyst],
        create: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst],
        resend: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst],
    },
    documents: {
        // TODO check permissions
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalystReadonly, Role.OperationsAnalyst, Role.ComplianceOfficer],
    },
    timeExpirations: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalystReadonly, Role.OperationsAnalyst],
    },
    riskProfiles: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.TechnicalUser, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.TechnicalUser,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        edit: [Role.ComplianceOfficer],
        recalculate: [Role.ComplianceOfficer],
    },
    riskRecalculations: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.TechnicalUser, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        create: [Role.TechnicalUser],
        cancel: [Role.TechnicalUser],
    },
    riskRuleSets: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.TechnicalUser, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.TechnicalUser,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        createRiskAlertRule: [Role.ComplianceOfficer],
        editRiskAlertRule: [Role.ComplianceOfficer],
        removeRiskAlertRule: [Role.ComplianceOfficer],
        editRiskRule: [Role.ComplianceOfficer],
        editRiskTierRules: [Role.ComplianceOfficer],
        recalculateRiskRule: [Role.ComplianceOfficer],
    },
    transactionAggregations: {
        view: [Role.OperationsManager, Role.OperationsAnalyst, Role.TechnicalUser, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.TechnicalUser,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        cancel: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.TechnicalUser,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
    },
    affiliations: {
        view: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalystReadonly,
            Role.OperationsAnalyst,
            Role.MerchantUser,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
        ],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalystReadonly,
            Role.OperationsAnalyst,
            Role.MerchantUser,
            Role.MerchantAnalyst,
            Role.MerchantAnalystReadonly,
            Role.DistributorUser,
            Role.DistributorManager,
            Role.AccountManagementCoordinator,
        ],
        create: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.MerchantUser,
            Role.MerchantAnalyst,
            Role.AccountManagementCoordinator,
        ],
        approve: [Role.DistributorUser, Role.DistributorManager],
        revokeApproval: [Role.DistributorUser, Role.DistributorManager],
        deactivate: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.MerchantUser,
            Role.MerchantAnalyst,
            Role.AccountManagementCoordinator,
        ],
        reactivate: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.MerchantUser,
            Role.MerchantAnalyst,
            Role.AccountManagementCoordinator,
        ],
        editTransactionAttributes: [
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.MerchantUser,
            Role.MerchantAnalyst,
            Role.AccountManagementCoordinator,
        ],
    },
    billingFiles: {
        view: [Role.MerchantUser, Role.DistributorUser, Role.DistributorManager, Role.TechnicalUser, Role.AccountManagementCoordinator],
        download: [Role.MerchantUser, Role.DistributorUser, Role.DistributorManager, Role.TechnicalUser, Role.AccountManagementCoordinator],
    },
    transactionFiles: {
        view: [Role.MerchantUser, Role.DistributorUser, Role.DistributorManager, Role.TechnicalUser, Role.AccountManagementCoordinator],
        download: [Role.MerchantUser, Role.DistributorUser, Role.DistributorManager, Role.TechnicalUser, Role.AccountManagementCoordinator],
    },
    apiIntegration: {
        view: [Role.TechnicalUser, Role.ApiIntegrationUser],
    },
    users: {
        view: [Role.TechnicalUser, Role.UserAdmin, Role.DistributorManager, Role.ComplianceOfficer, Role.AccountManagementCoordinator],
        viewSignInLogs: [Role.TechnicalUser, Role.UserAdmin, Role.DistributorManager],
        viewAuditLogs: [Role.TechnicalUser, Role.UserAdmin, Role.DistributorManager],
        viewAuditTrail: [Role.TechnicalUser, Role.UserAdmin, Role.DistributorManager],
        viewLookupLogs: [Role.TechnicalUser, Role.UserAdmin],
        create: [Role.UserAdmin, Role.DistributorManager],
        edit: [Role.UserAdmin, Role.DistributorManager],
        delete: [Role.UserAdmin, Role.DistributorManager],
        block: [Role.UserAdmin, Role.DistributorManager],
        activate: [Role.UserAdmin, Role.DistributorManager],
        resetSecondFactor: [Role.UserAdmin, Role.DistributorManager],
        resetTotp: [Role.UserAdmin, Role.DistributorManager],
        resetPassword: [Role.UserAdmin, Role.DistributorManager],
    },
    cbmExportFiles: {
        view: [Role.TechnicalUser, Role.FinanceOfficer],
        create: [Role.TechnicalUser, Role.FinanceOfficer],
        reprocess: [Role.TechnicalUser, Role.FinanceOfficer],
        download: [Role.TechnicalUser, Role.FinanceOfficer],
    },
    cesopExportFiles: {
        view: [Role.TechnicalUser, Role.FinanceOfficer],
        create: [Role.TechnicalUser, Role.FinanceOfficer],
        download: [Role.TechnicalUser, Role.FinanceOfficer],
        send: [Role.TechnicalUser, Role.FinanceOfficer],
        refreshSubmission: [Role.TechnicalUser, Role.FinanceOfficer],
        abortSubmission: [Role.TechnicalUser, Role.FinanceOfficer],
        confirmSubmission: [Role.TechnicalUser, Role.FinanceOfficer],
    },
    monitoringRules: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
        create: [Role.TechnicalUser],
        edit: [Role.TechnicalUser],
        enable: [Role.TechnicalUser],
        disable: [Role.TechnicalUser],
        run: [Role.TechnicalUser],
    },
    monitoringAlerts: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
    },
    customerQnAQuestionnaires: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.ComplianceOfficer],
        edit: [Role.OperationsManager, Role.OperationsAnalyst],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    customerQnAAssignments: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.ComplianceOfficer],
        edit: [Role.OperationsManager, Role.OperationsAnalyst],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    otpAuthFactors: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
        create: [Role.OperationsManager, Role.OperationsAnalyst],
        remove: [Role.OperationsManager, Role.OperationsAnalyst],
    },
    pins: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
    },
    authenticationFlows: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
        viewAuditTrail: [
            Role.TechnicalUser,
            Role.OperationsManager,
            Role.OperationsAnalyst,
            Role.OperationsAnalystReadonly,
            Role.ComplianceOfficer,
        ],
    },
    redirectSessions: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
    },
    credentials: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly],
    },
    documentRecognitions: {
        view: [Role.TechnicalUser, Role.OperationsManager, Role.OperationsAnalyst, Role.OperationsAnalystReadonly, Role.ComplianceOfficer],
    },
};

export type PermissionSet = typeof permissions;

export type AccountPermissionSet = {
    [PermissionGroup in keyof PermissionSet]: {
        [Permission in keyof PermissionSet[PermissionGroup]]: boolean;
    };
};

type Account = {
    account?: AccountInfo;
    roles: string[];
    permissions: AccountPermissionSet;
};

type AccountState = {
    account: Account;
};

const initialPermissions = Object.entries(permissions).reduce(
    (acc, [key, value]) => ({
        ...acc,
        [key]: Object.keys(value).reduce((group, groupKey) => ({ ...group, [groupKey]: false }), {}),
    }),
    {} as any,
);

const initialState: AccountState = { account: { roles: [], permissions: initialPermissions } };

const { useGlobalState, getGlobalState, setGlobalState } = createGlobalState(initialState);

function getRoles(account: AccountInfo) {
    return getClaim<string[]>(account, 'groups')?.sort() ?? [];
}

function inRoles(source: string[], roles: string[]) {
    return roles.some((role) => source.some((current) => current.startsWith(role)));
}

const updateGlobalState = (account: AccountInfo) => {
    const state = getGlobalState('account');

    const authenticatedAccount = account?.idTokenClaims ? account : undefined;
    const roles = authenticatedAccount ? getRoles(authenticatedAccount) : [];

    if (roles.join(',') !== state.roles.join(',') || account.localAccountId !== state.account?.localAccountId) {
        const newPermissions = { ...initialPermissions };
        const unsafePermissions = permissions as any;

        for (const group in permissions) {
            for (const permission in unsafePermissions[group]) {
                newPermissions[group][permission] = inRoles(roles, unsafePermissions[group][permission]);
            }
        }

        setGlobalState('account', { account: authenticatedAccount ?? undefined, roles, permissions: newPermissions });
    }
};

msal.addEventCallback((event) => {
    if (ofType(EventType.ACQUIRE_TOKEN_SUCCESS, event)) {
        updateGlobalState(event.payload.account);
    } else if (ofType(EventType.LOGIN_SUCCESS, event)) {
        updateGlobalState(event.payload);
    }
});

export const useAccount = () => {
    const [accountState] = useGlobalState('account');

    const hasRoles = useCallback(
        (roles: Role[]) => {
            return roles.some((role) => accountState.roles.some((current) => current.startsWith(role)));
        },
        [accountState.roles],
    );

    return { ...accountState, hasRoles };
};
