import { Card, makeStyles, mergeClasses } from '@fluentui/react-components';
import { useMediaQuery } from 'react-responsive';

import { ListViewCard } from './ListViewCard';
import { ListViewTable } from './ListViewTable';

type ColumnType = 'content' | 'action' | 'responsive';

export type ColumnAlignment = 'left' | 'center' | 'right';

export type ColumnRender<T> = {
    (item: T): React.ReactNode;
};

export type ResponsiveColumnRender<T> = {
    (item: T): {
        header?: React.ReactNode;
        content?: React.ReactNode;
        footer: React.ReactNode;
        href?: string;
        onClick?: () => void;
    };
};

export type ColumnConfig<T = any> = {
    align?: ColumnAlignment;
    onRender: ColumnRender<T>;
    onFooterRender?: ColumnRender<T[]>;
};

export type ActionColumnConfig<T = any> = Pick<ColumnConfig<T>, 'onRender'>;

export type TableColumn<T = any> = Required<Omit<ColumnConfig<T>, 'onFooterRender'>> &
    Pick<ColumnConfig<T>, 'onFooterRender'> & {
        label?: string;
        type: Exclude<ColumnType, 'responsive'>;
    };

export type ResponsiveColumn<T = any> = {
    type: Exclude<ColumnType, 'content' | 'action'>;
    onRender: ResponsiveColumnRender<T>;
};

export type Column<T = any> = TableColumn<T> | ResponsiveColumn<T>;

function getColumn<T>(
    title: string | undefined,
    type: Exclude<ColumnType, 'responsive'>,
    renderOrConfig: ColumnRender<T> | ColumnConfig<T>,
): Column<T> | ResponsiveColumn<T> {
    if (typeof renderOrConfig === 'function') {
        return {
            label: title,
            type,
            align: 'left',
            onRender: renderOrConfig,
        };
    } else {
        return {
            label: title,
            type,
            align: renderOrConfig.align ?? 'left',
            onFooterRender: renderOrConfig.onFooterRender,
            onRender: renderOrConfig.onRender,
        };
    }
}

export type ColumnFactory<T> = {
    (label: string, renderOrConfig: ColumnRender<T> | ColumnConfig<T>): void;
};

export type ResponsiveColumnFactory<T> = {
    (render: ResponsiveColumnRender<T>): void;
};

export type ActionColumnFactory<T> = {
    (render: ColumnRender<T>): void;
};

export const useListView = <T,>(
    configure: (helpers: {
        column: ColumnFactory<T>;
        actionColumn: ActionColumnFactory<T>;
        responsiveColumn: ResponsiveColumnFactory<T>;
    }) => void,
): Column<T>[] => {
    const columns: (Column<T> | ResponsiveColumn<T>)[] = [];

    const column: ColumnFactory<T> = (label, renderOrConfig) => {
        columns.push(getColumn<T>(label, 'content', renderOrConfig));
    };

    const actionColumn: ActionColumnFactory<T> = (render) => {
        columns.push(getColumn<T>(undefined, 'action', render));
    };

    const responsiveColumn: ResponsiveColumnFactory<T> = (render) => {
        columns.push({ type: 'responsive', onRender: render });
    };

    configure({ column, actionColumn, responsiveColumn });

    return columns;
};

export type ListViewProps<T = any> = {
    className?: string;
    enableShimmer?: boolean;
    items?: T[];
    columns: Column[];
};

export const ListView: React.FC<ListViewProps> = ({ columns, ...props }) => {
    const responsiveConfig = columns.find((column) => column.type === 'responsive');
    const tableConfig = columns.filter((column) => column.type !== 'responsive');

    const classes = useStyles();

    const wide = useMediaQuery({ minWidth: 1024 }, undefined, () => {});

    const loading = props.enableShimmer;
    const items = loading ? undefined : props.items?.length ? props.items : undefined;

    return (
        <Card className={mergeClasses(classes.root, props.className)}>
            {wide || !responsiveConfig ? (
                <ListViewTable items={items} loading={loading} config={tableConfig} />
            ) : (
                <ListViewCard items={items} loading={loading} config={responsiveConfig} />
            )}
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        padding: 0,
    },
});
